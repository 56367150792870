<template>
  <div id="app">

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <span :key="index" v-for="(account, index) in accounts"  >
                          <img style="width: 60px" class="img-fluid" :src="account.last_nft" alt="" v-if="account.last_nft">
            </span>
          </div>
          <!-- /.text-center -->
          <br>
        </div>
        <!-- /.col-12 -->
        <div class="col-12">
          <button class="btn btn-primary" @click="getAllAccountsTlm">Fetch TLM</button> &nbsp;
          <button class="btn btn-primary" @click="getAllAccountsCpu">Fetch CPU</button> &nbsp;
          <button class="btn btn-primary" @click="getFWWBalance">Fetch FWW</button> &nbsp;
          <button class="btn btn-info" @click="sortAccountsByTlm">Sort TLM</button>  &nbsp;
          <button class="btn btn-info" @click="sortAccountsByCpu">Sort CPU</button>  &nbsp;
          <!-- /.btn btn-primary -->
        </div>
        <!-- /.col-12 -->
      </div>
      <!-- /.row -->
      <br><br>
      Total FWW: {{ totalFWW.toFixed(2) }} x {{fwwToWax.toFixed(4)}} WAX |
      {{ (totalFWW * fwwToWax).toFixed(2) }} WAX | ${{ ((totalFWW * fwwToWax) * waxToUsdt).toFixed(2) }} | R$ {{totalFWWemReais.toFixed(2)}}
      <br> <br>

      Total FWG: {{ totalFWG.toFixed(2) }} x {{fwgToWax.toFixed(4)}} WAX |
      {{ (totalFWG * fwgToWax).toFixed(2) }} WAX | ${{ ((totalFWG * fwgToWax) * waxToUsdt).toFixed(2) }} | R$ {{totalFWGemReais.toFixed(2)}}
      <br> <br>

      Total FWF: {{ totalFWF.toFixed(2) }} x {{fwfToWax.toFixed(4)}} WAX |
      {{ (totalFWF * fwfToWax).toFixed(2) }} WAX | ${{ ((totalFWF * fwfToWax) * waxToUsdt).toFixed(2) }} | R$ {{totalFWFemReais.toFixed(2)}}
      <br> <br>

      <b>Total TLM:</b> {{ totalTLM.toFixed(2) }} x ${{ tldusdt }} | ${{ (totalTLM * tldusdt).toFixed(2) }} |
      R${{ totalReais }} | {{ totalWAX.toFixed(2) }} WAX
<!--      R${{ ((totalTLM * tldusdt) * usdtbrl).toFixed(2) }}-->
      <table class="table">
        <thead class="thead-dark">
        <tr>
          <th scope="col">Account</th>
          <th scope="col">TLM</th>
          <th scope="col">R$</th>
          <th scope="col">CPU</th>
          <th scope="col">CPU ms</th>
          <th scope="col">CPU Wax</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr :key="index" v-for="(account, index) in accounts">
          <td>{{ account.key }}</td>
          <th scope="row">{{ account.tlm.toFixed(2) }}</th>
          <th scope="row">R${{ ((account.tlm * tldusdt) * usdtbrl).toFixed(2) }}</th>
          <th scope="row">{{ account.cpu.toFixed(2) }} %</th>
          <th scope="row">{{ account.cpu_ms.toFixed(2) }} ms</th>
          <th scope="row">{{ account.cpu_weight.toFixed(2) }} WAX</th>
          <td>
            <a :href="'https://wax.bloks.io/account/'+account.key" target="_blank" class="btn btn-sm btn-info">bloks</a> &nbsp;
            <a :href="'https://wax.atomichub.io/profile/'+account.key" target="_blank" class="btn btn-sm btn-warning">atomic</a>
            <a :href="'https://mining.alienworlds.tools/?account='+account.key" target="_blank" class="btn btn-sm btn-secondary">stats</a>
            <!-- /.btn btn-sm btn-info -->
          </td>

        </tr>

        </tbody>
      </table>

    </div>
    <!-- /.container -->
  </div>
</template>

<script>
// sleep time expects milliseconds
// function sleep (time) {
//   return new Promise((resolve) => setTimeout(resolve, time));
// }

import {sortBy} from 'lodash-es';

//const API = 'https://chain.wax.io';
const API = 'https://api.waxnet.io';
// const API = 'http://wax-bp.wizardsguild.one';
// const API = 'https://api.wax.alohaeos.com';

export default {
  name: 'App',
  created() {
    this.getTlmUsdtTicker();
    setInterval(this.getTlmUsdtTicker, 30000)
  },
  watch:{
    totalReais(){
      this.setPageTitle();
    },
    totalFWWemReais(){
      this.setPageTitle();
    }
  },
  data() {
    return {
      accounts: [
        { key: 's31mk.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: 'ez1mk.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: 'nug2k.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: '32n2g.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: 'fql2g.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: 'eja1s.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: 'u32ls.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: 'osr1q.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        { key: 'ekulq.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},

        // { key: 'm.federation', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'ulw.q.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '5d4ki.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '5.lki.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'c3f.g.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'lhhkg.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '3tgkg.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'x55kg.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'o.v.g.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'nwpkg.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'owl.g.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'b.jkg.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'eog.g.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '1bb.e.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'xv5.e.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '4r1ke.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'hexke.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'o2s.e.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '1iake.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'z1v.a.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: '.puka.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'jjt.2.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'xzrk2.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'zbqk2.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'gjq.2.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'wbo.2.c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'se2k..c.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'gnsjw.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: '.fuzw.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         { key: 'nu.gm', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'waxisdown.gm', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '3vtjw.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'zt3iu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'y32t4.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'lzkd4.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'do3ji.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'n15iu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '2qqzi.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'pisji.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'w3czk.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'bs5ta.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'rd4iu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '13.js.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'jkmta.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'qaita.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'hd3js.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: '4ztdc.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'sswiu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'duxti.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'v1.du.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: '.ouiu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'zdvuk.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'a52yu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'bdazs.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: '4hwuk.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'mkyiu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
         // { key: 'th.iu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: '', skip_cpu:1},
         // { key: 'ivsu.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'qcbbe.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'msibe.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: '32wrs.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: '4yvrs.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'kvwbm.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'h2frm.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'fykbm.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'komrk.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'jqxrs.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
        // { key: 'pisco.wam', tlm:0, cpu:0, cpu_ms:0, cpu_weight: 0, last_nft: ''},
      ],
      tldusdt: 0,
      usdtbrl: 0,
      totalFWW: 0,
      totalFWG: 0,
      totalFWF: 0,
      fwwToWax: 0,
      fwgToWax: 0,
      fwfToWax: 0,
      waxToUsdt: 0
    }
  },
  computed:{
    totalTLM(){
      return this.accounts.filter(a => a.key !== 'm.federation').reduce((acc, item)=>{ acc+=item.tlm; return acc }, 0)
    },
    totalWAX(){
      return this.accounts.filter(a => a.key !== 'm.federation').reduce((acc, item)=>{ acc+=item.cpu_weight; return acc }, 0)
    },
    totalReais(){
      let val = ((this.totalTLM * this.tldusdt) * this.usdtbrl).toFixed(2);
      // document.title  = `R$${val}`;
      return val;
    },
    totalFWWemReais(){
      return ((this.totalFWW * this.fwwToWax) * this.waxToUsdt) * this.usdtbrl
    },
    totalFWGemReais(){
      return ((this.totalFWG * this.fwgToWax) * this.waxToUsdt) * this.usdtbrl
    },
    totalFWFemReais(){
      return ((this.totalFWF * this.fwfToWax) * this.waxToUsdt) * this.usdtbrl
    }
  },
  methods:{
    setPageTitle(){
      let sum = parseFloat(this.totalReais) + this.totalFWWemReais + this.totalFWGemReais + this.totalFWFemReais
      sum = sum.toFixed(2)
      document.title  = `R$${sum} - $${this.tldusdt}`
    },
    async getFWWBalance(){
      await fetch(`${API}/v1/chain/get_table_rows`, {
        "headers": {
          "accept": "*/*",
        },
        "body": "{\"json\":true,\"code\":\"farmersworld\",\"scope\":\"farmersworld\",\"table\":\"accounts\",\"lower_bound\":\"nu.gm\",\"upper_bound\":\"nu.gm\",\"index_position\":1,\"key_type\":\"i64\",\"limit\":\"100\",\"reverse\":false,\"show_payer\":false}",
        "method": "POST",
      }).then(r=>r.json()).then(r => {
        this.totalFWW = parseFloat(r.rows[0].balances.filter(n => /WOOD/.test(n))[0].replace(' WOOD', ''))
        this.totalFWG = parseFloat(r.rows[0].balances.filter(n => /GOLD/.test(n))[0].replace(' GOLD', ''))
        this.totalFWF = parseFloat(r.rows[0].balances.filter(n => /FOOD/.test(n))[0].replace(' FOOD', ''))
      });
    },
    async getTlmUsdtTicker(){
      await fetch('https://api.binance.com/api/v3/ticker/price?symbol=TLMUSDT').then(r => r.json()).then((res)=>{
        this.tldusdt = parseFloat(res.price);
      });
      await fetch('https://api.binance.com/api/v3/ticker/price?symbol=WAXPUSDT').then(r => r.json()).then((res)=>{
        this.waxToUsdt = parseFloat(res.price);
      });
      await fetch('https://api.binance.com/api/v3/ticker/price?symbol=USDTBRL').then(r => r.json()).then((res)=>{
        this.usdtbrl = parseFloat(res.price);
      })
      await fetch(`${API}/v1/chain/get_table_rows`, {
        "body": "{\"json\":true,\"code\":\"alcorammswap\",\"scope\":\"alcorammswap\",\"table\":\"pairs\",\"table_key\":\"\",\"lower_bound\":501,\"upper_bound\":501,\"index_position\":1,\"key_type\":\"\",\"limit\":1,\"reverse\":false,\"show_payer\":false}",
        "method": "POST",
      }).then(r=>r.json()).then(r => {
        const pool1 = r.rows[0].pool1.quantity.split(' ')[0]
        const pool2 = r.rows[0].pool2.quantity.split(' ')[0]
        this.fwwToWax = parseFloat(pool1) / parseFloat(pool2)
      });

      await fetch(`${API}/v1/chain/get_table_rows`, {
        "body": "{\"json\":true,\"code\":\"alcorammswap\",\"scope\":\"alcorammswap\",\"table\":\"pairs\",\"table_key\":\"\",\"lower_bound\":503,\"upper_bound\":503,\"index_position\":1,\"key_type\":\"\",\"limit\":1,\"reverse\":false,\"show_payer\":false}",
        "method": "POST",
      }).then(r=>r.json()).then(r => {
        const pool1 = r.rows[0].pool1.quantity.split(' ')[0]
        const pool2 = r.rows[0].pool2.quantity.split(' ')[0]
        this.fwgToWax = parseFloat(pool1) / parseFloat(pool2)
      });

      await fetch(`${API}/v1/chain/get_table_rows`, {
        "body": "{\"json\":true,\"code\":\"alcorammswap\",\"scope\":\"alcorammswap\",\"table\":\"pairs\",\"table_key\":\"\",\"lower_bound\":489,\"upper_bound\":489,\"index_position\":1,\"key_type\":\"\",\"limit\":1,\"reverse\":false,\"show_payer\":false}",
        "method": "POST",
      }).then(r=>r.json()).then(r => {
        const pool1 = r.rows[0].pool1.quantity.split(' ')[0]
        const pool2 = r.rows[0].pool2.quantity.split(' ')[0]
        this.fwfToWax = parseFloat(pool1) / parseFloat(pool2)
      });

    },
    sortAccountsByTlm(){
      this.accounts = sortBy(this.accounts, (acc)=>{ return acc.tlm});
    },
    sortAccountsByCpu(){
      this.accounts = sortBy(this.accounts, (acc)=>{ return acc.cpu});
    },
    getAllAccountsNft(){
      // let index = 0;
      // this.accounts.forEach((account)=>{
      //   this.getLastNft(account.key, index);
      //   index += 1;
      // });
    },
    getAllAccountsCpu(){
      let index = 0;
      this.accounts.forEach((account)=>{
        if (!account.skip_cpu){
          this.getCPU(account.key, index);
        }
        index += 1;
      });
    },
    getAllAccountsTlm(){
      let index = 0;
      this.accounts.forEach((account)=>{
          this.getTLM(account.key, index);
        index += 1;
      });

      this.getAllAccountsNft();
    },
    getTLM(account,index){
      let $this = this;
      // fetch(`https://api.waxsweden.org/v1/chain/get_table_rows`, {
      // fetch(`https://chain.wax.io/v1/chain/get_table_rows`, {
      // fetch(`http://wax-bp.wizardsguild.one/v1/chain/get_table_rows`, {
      fetch(`${API}/v1/chain/get_table_rows`, {
        method: 'post',
        body: `{"json":true,"code":"alien.worlds","scope":"${account}","table":"accounts","table_key":"","lower_bound":"","upper_bound":"","index_position":1,"key_type":"","limit":1,"reverse":false,"show_payer":false}`
      }).then((r)=>{
        return r.json();
      }).then((res)=>{
        let amount = res.rows[0].balance.split(' ')[0];
        amount = parseFloat(amount);
        $this.accounts[index].tlm = amount;
      })
    },
    getCPU(account,index){
      let $this = this;
      // fetch(`https://wax.greymass.com/v1/chain/get_account`, {
      // fetch(`https://chain.wax.io/v1/chain/get_account`, {
      fetch(`${API}/v1/chain/get_account`, {
        method: 'post',
        body: `{"account_name":"${account}"}`
      }).then((r)=>{
        return r.json();
      }).then((res)=>{
        let cpu = ( res.cpu_limit.used * 100) / res.cpu_limit.max;
        $this.accounts[index].cpu = cpu;
        $this.accounts[index].cpu_ms = res.cpu_limit.max /1000;
        $this.accounts[index].cpu_weight = res.cpu_weight / 100000000;
      })
    },
    getLastNft(account,index){
      let $this = this;

      fetch(`https://wax.api.atomicassets.io/atomicassets/v1/assets?page=1&limit=1&owner=${account}&match=&collection_name=&schema_name=&template_id=&collection_whitelist=alien.worlds&sort=updated&order=desc`).then((r)=>{
        return r.json();
      }).then((res)=>{
        let img = res.data[0].template.immutable_data.img;
        $this.accounts[index].last_nft = `https://ipfs.io/ipfs/${img}`;
      })
    },
  }

}
</script>

<style lang="scss">
body{
  background: #212121;
  color: white;
}
.table{
  color: white;
}
.table td, .table th {
  border-top: 1px solid #343a40;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //color: #2c3e50;
  margin-top: 15px;
}

.card{
  margin-bottom: 15px;
}

</style>
